import { navigate } from "gatsby"
import React from "react"
import { useRefreshToken } from "../../../hooks/useRefreshToken"
import Loader from "../../components/Loader/Loader"
import Schedule from "../../Schedule"

const SchedulePage = () => {
  const { loginUser, checking } = useRefreshToken()

  if (checking) {
    return <Loader />
  }

  return <>{loginUser ? <Schedule /> : navigate("/venue/login")}</>
}
export default SchedulePage

import React from "react"
import "./Spinner2.css"

const Spinner2 = () => {
  return (
    <div class="ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner2

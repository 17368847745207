import { useEffect, useState } from "react"
import { auth } from "../firebase/firebase-config"
import { useDispatch } from "react-redux"
import { setUser } from "../redux/slices/userSlice"

export const useRefreshToken = () => {
  const [loginUser, setLoginUser] = useState()
  const [checking, setChecking] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult()
        const expirationTime =
          new Date(idTokenResult.expirationTime).getTime() / 1000
        const currentTime = Math.floor(Date.now() / 1000)

        if (expirationTime < currentTime) {
          const { refreshToken } = user

          const newIdTokenResult = await user.getIdTokenResult(true)
          const newToken = newIdTokenResult.token
          const newExpirationTime = newIdTokenResult.expirationTime

          console.log("1", expirationTime)
          console.log("2", currentTime)

          const newUser = {
            ...user,
            refreshToken,
            token: newToken,
            expirationTime: newExpirationTime,
          }

          dispatch(setUser(newUser))
          setLoginUser(newUser)
          setChecking(false)
        } else {
          dispatch(setUser(user))
          setLoginUser(user)
          setChecking(false)
        }
      } else {
        setChecking(false)
      }
    })
  }, [dispatch])

  return { loginUser, checking }
}
